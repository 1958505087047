import { call, put } from 'redux-saga/effects';
import packageJson from 'containers/../../package.json';
import fetchWrapper from 'utils/Api/fetchWrapper';
import { push } from 'connected-react-router';
import { createAuthenticationRequest } from 'containers/Authentication/actions';
import { getBrandDomainName, channel, isBrandedDomain } from 'utils/utils';
import { serviceResponse } from '../actions';

const serviceActionsMap = {
  connect: ({ sessionId }) => push(`/connect/${sessionId}`),
  protect: createAuthenticationRequest,
};

export default function* service({ payload: { code, origin, referrer, ...params } }) {
  const url = 'proxy/service';

  try {
    const response = yield call(fetchWrapper, {
      method: 'POST',
      url,
      params: {
        code,
        referrer,
        origin,
        ...params,
        applicationVersion: packageJson.version,
        channel,
        domain: isBrandedDomain() ? getBrandDomainName() : null,
      },
    });

    yield put(serviceResponse(response));

    const serviceAction = serviceActionsMap[response.service?.name];
    if (serviceAction) {
      yield put(serviceAction({ sessionId: response.session }));
    }
  } catch ({ response }) {
    yield put(serviceResponse());
    if (response?.status === 400) {
      yield put(push(`/code`));
    }
  }
}

import React from 'react';
import useUiDesginSystemComponentSwitcher from 'components/hooks/useUiDesginSystemComponentSwitcher';
import UploadProgressModalCertilogo from './certilogo';
import UploadProgressModalEbay from './ebay';

const componentsMap = {
  certilogo: UploadProgressModalCertilogo,
  ebay: UploadProgressModalEbay,
};

const UploadProgressModal = (props) => {
  const Component = useUiDesginSystemComponentSwitcher(componentsMap);
  return <Component {...props} />;
};

export default UploadProgressModal;

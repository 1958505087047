import React, { useEffect, useState, useMemo } from 'react';
import { Button, Br, Slider, Text } from '@ebay-certilogo/design-system-web';
import PropTypes from 'prop-types';
import _, { isEmpty } from 'lodash';
import get from 'lodash/get';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { useRouteMatch, Link } from 'react-router-dom';
import { logCta, setBack } from 'containers/App/actions';
import HeaderBox from 'containers/Authentication/components/HeaderBox';
import { flowRequest, imageListRequest, resetImageUpload } from 'containers/Authentication/actions';
import {
  selectAuthenticationImageUploadLoading,
  selectAuthenticationImageUploadCount,
} from 'containers/Authentication/selectors';
import Switch from 'components/Switch';
import Route from 'components/Route';
import LoadingIndicator from 'components/LoadingIndicator';
import ButtonComponent from 'components/ButtonComponent';
import withColorType from 'components/HOC/withColorType';
import FlowButton from 'containers/Authentication/components/FlowButton';
import { imageUploadTakePicture } from 'utils/reduxBeaconGtm/actions/authentication';
import { removeEmptyObjects } from 'utils/utils';
import LaterPopup from 'components/Camera/LaterPopup';
import useUiDesginSystemComponentSwitcher from 'components/hooks/useUiDesginSystemComponentSwitcher';
import Camera from './Camera';
import StartModal from './StartModal';
import { Image, SliderWrapper } from './styles';
import defaultMessages from './defaultMessages';

function ImageUpload({ colorType, authenticationId, data }) {
  const { status, props } = data;
  const dispatch = useDispatch();
  const { path } = useRouteMatch();
  const [laterPopupIsOpen, setLaterPopupIsOpen] = useState(false);
  const isLoading = useSelector(selectAuthenticationImageUploadLoading);

  const uploadedImagesCount = useSelector(selectAuthenticationImageUploadCount);
  const minImages = get(props, 'image.min');
  const config = get(props, 'config', {});

  const log = (name, params) => {
    dispatch(
      logCta({
        type: 'event',
        event: { type: 'imageUpload', name },
        action: `imageUpload.${name}`,
        authenticationId,
        sessionId: data?.sessionId,
        params: {
          preprocessing: data?.props?.config?.camera?.preprocessing,
          primaryBrand: data?.primaryBrand,
          tmBrand: data?.tmBrand,
          media: data?.props?.config?.media,
          useCase: data?.props?.config?.useCase,
          configName: data?.props?.config?.name,
          flow: data?.flow,
          ...params,
        },
      }),
    );
  };

  const componentWillUnmount = () => {
    dispatch(resetImageUpload());
  };

  useEffect(() => {
    log('page');
    dispatch(imageListRequest({ source: 'authentication', sourceId: authenticationId }));
    dispatch(
      setBack({
        back: flowRequest({
          action: 'prev',
          status,
          authenticationId,
        }),
      }),
    );
    return componentWillUnmount;
  }, []);

  const flowNext = () => {
    dispatch(
      flowRequest({
        action: 'next',
        status,
        authenticationId,
      }),
    );
  };

  const handleSkip = () => {
    log('pageSkip');
    flowNext();
  };

  const handleLater = () => {
    if (uploadedImagesCount < minImages) {
      setLaterPopupIsOpen(true);
    } else {
      handleSkip();
    }
  };

  const { introImages, showStartModal, ...configMessages } = config;

  const messages = useMemo(
    () => _.merge({}, defaultMessages, removeEmptyObjects(configMessages)),
    [configMessages],
  );

  const buttons = useUiDesginSystemComponentSwitcher({
    certilogo: (
      <>
        <ButtonComponent
          component={FlowButton}
          to="image_upload/camera"
          colorType={colorType}
          noMargin
          onClick={() => {
            dispatch(imageUploadTakePicture());
          }}
        >
          <FormattedMessage id={messages.buttonStart} defaultMessage={messages.buttonStart} />
        </ButtonComponent>
        <Button onClick={handleLater} colorType={colorType} buttonType="link" arrow={false}>
          <FormattedMessage id={messages.buttonLater} defaultMessage={messages.buttonLater} />
        </Button>
      </>
    ),
    ebay: (
      <>
        <Button
          as={Link}
          rounded
          arrow={false}
          size="s"
          fontSize="base"
          to="image_upload/camera"
          colorType={colorType}
          onClick={() => {
            dispatch(imageUploadTakePicture());
          }}
          uppercase={false}
        >
          <FormattedMessage id={messages.buttonStart} defaultMessage={messages.buttonStart} />
        </Button>
        <Button
          rounded
          arrow={false}
          size="s"
          fontSize="base"
          style={{
            alignSelf: 'flex-start',
            border: '1px solid #0968F6',
            color: '#0968F6',
          }}
          buttonType="transparent"
          onClick={handleLater}
          colorType={colorType}
          uppercase={false}
        >
          <FormattedMessage id={messages.buttonLater} defaultMessage={messages.buttonLater} />
        </Button>
      </>
    ),
  });

  return (
    <Switch>
      <Route path={path} exact>
        {isLoading ? (
          <LoadingIndicator />
        ) : (
          <>
            {showStartModal && <StartModal messages={messages} colorType={colorType} />}
            <HeaderBox
              monochrome
              colorType={colorType}
              marginBottom="xl"
              text={
                <FormattedMessage
                  id={messages.title}
                  defaultMessage={messages.title}
                  values={{ linebreak: <Br /> }}
                />
              }
              description={
                !isEmpty(introImages) && (
                  <Text size="s">
                    <FormattedMessage
                      id={messages.introImagesDescription}
                      defaultMessage={messages.introImagesDescription}
                    />
                  </Text>
                )
              }
            />
            {!isEmpty(introImages) && (
              <SliderWrapper>
                <Slider arrows={false} showsButtons={false}>
                  {introImages.map((introImage) => (
                    <Image
                      src={introImage.url}
                      source={{ url: introImage.url }}
                      alt={introImage.id}
                      margin={{ size: 's', position: 'bottom' }}
                    />
                  ))}
                </Slider>
              </SliderWrapper>
            )}
            {buttons}
            <LaterPopup
              colorType={colorType}
              isOpen={laterPopupIsOpen}
              setIsOpen={setLaterPopupIsOpen}
              handleSubmit={handleSkip}
              message={
                <FormattedMessage
                  id={messages.laterPopup.message}
                  defaultMessage={messages.laterPopup.message}
                />
              }
              primaryButtonMessage={
                <FormattedMessage
                  id={messages.laterPopup.primaryButton}
                  defaultMessage={messages.laterPopup.primaryButton}
                />
              }
              secondaryButtonMessage={
                <FormattedMessage
                  id={messages.laterPopup.secondaryButton}
                  defaultMessage={messages.laterPopup.secondaryButton}
                />
              }
            />
          </>
        )}
      </Route>
      <Route path={`${path}/camera`} exact>
        <Camera
          colorType={colorType}
          authenticationId={authenticationId}
          data={data}
          flowNext={flowNext}
          messages={messages.camera}
          log={log}
        />
      </Route>
    </Switch>
  );
}

ImageUpload.propTypes = {
  colorType: PropTypes.string,
  authenticationId: PropTypes.string,
  data: PropTypes.object,
};

export default withColorType(ImageUpload);

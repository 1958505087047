import UAParser from 'ua-parser-js';
import { isFunction, isEmpty, isArray, isPlainObject, fromPairs } from 'lodash';

const uaParser = new UAParser();

export const getCookiebyName = (name, defaultValue = null) => {
  const pair = document.cookie.match(new RegExp(`${name}=([^;]+)`));
  return pair ? pair[1] : defaultValue;
};

export const isMobile = ['mobile', 'tablet'].includes(uaParser.getDevice().type);

export const isSupportedOS = ['iOS', 'Android'].includes(uaParser.getOS().name);

export const isIOS = ['iOS'].includes(uaParser.getOS().name);

const isGetUserMediaSupported = () =>
  !!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia) ||
  !!navigator.getUserMedia ||
  !!navigator.webkitGetUserMedia ||
  !!navigator.mozGetUserMedia;

export const isCameraEnabled = () => isMobile && isSupportedOS && isGetUserMediaSupported();

export const isChina = ['production-cn', 'staging-cn'].includes(process.env.REACT_APP_ENV);

export const channel = isMobile ? 'mobile' : 'web';

export const cleanPathname = (pathname) =>
  pathname.replace(/\d{6}/g, '<digits>').replace(/[a-f0-9]{24}/g, '<hash>');

export const getBrandDomainName = () => {
  const domain = document.location.hostname.split('.').shift();
  return ['www', 'certilogo', 'localhost', 'stage4', 'stage', 'w3'].includes(domain)
    ? 'default'
    : domain;
};

export const isBrandedDomain = () => getBrandDomainName() !== 'default';

export const isGoogleMapsEnabled = () =>
  // eslint-disable-next-line no-undef
  typeof google === 'object' && typeof google.maps === 'object';

export const removeEmptyObjects = (o) => {
  if (isFunction(o) || !isPlainObject(o)) return o;

  if (isArray(o)) return o.map(removeEmptyObjects);

  return fromPairs(
    Object.entries(o)
      .map(([k, v]) => [k, removeEmptyObjects(v)])
      .filter(([, v]) => !isEmpty(v)),
  );
};

export const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.0/8 are considered localhost for IPv4.
    window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/),
);

/**
 * app.js
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

// Needed for redux-saga es6 generator support
// import '@babel/polyfill';

// Import all the third party stuff
import React from 'react';
// import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { GoogleOAuthProvider } from '@react-oauth/google';
import * as Sentry from '@sentry/react';
import history from 'utils/history';
import config from 'config';

// Import root app
import App from 'containers/App';

import ErrorComponent from 'components/ErrorComponent';

// Import Language Provider
import LanguageProvider from 'containers/LanguageProvider';
import { PersistGate } from 'redux-persist/integration/react';
// Load the favicon and the .htaccess file
// import '!file-loader?name=[name].[ext]!./images/favicon.ico';
// import 'file-loader?name=.htaccess!./.htaccess'; // eslint-disable-line import/extensions
import { persistStore } from 'redux-persist';

import configureStore from '../configureStore';

// Import i18n messages
import { translationMessages } from '../i18n';
import ThemeProvider from './ThemeProvider';

// Create redux store with history
const initialState = {};
const store = configureStore(initialState, history);
const persistor = persistStore(store);
// const MOUNT_NODE = document.getElementById('app');

const render = () => {
  return (
    <Sentry.ErrorBoundary fallback={ErrorComponent}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <LanguageProvider messages={translationMessages}>
            <GoogleOAuthProvider clientId={config.oauth.google.clientId}>
              <ConnectedRouter history={history} basename={config.basename}>
                <ThemeProvider>
                  <App />
                </ThemeProvider>
              </ConnectedRouter>
            </GoogleOAuthProvider>
          </LanguageProvider>
        </PersistGate>
      </Provider>
    </Sentry.ErrorBoundary>
  );
};

// if (module.hot) {
//   // Hot reloadable React components and translation json files
//   // modules.hot.accept does not accept dynamic dependencies,
//   // have to be constants at compile-time
//   module.hot.accept(['../../i18n', 'containers/App'], () => {
//     ReactDOM.unmountComponentAtNode(MOUNT_NODE);
//     render(translationMessages);
//   });
// }

// Chunked polyfill for browsers without Intl support
// if (!window.Intl) {
//   new Promise((resolve) => {
//     resolve(import('intl'));
//   })
//     .then(() =>
//       Promise.all([import('intl/locale-data/jsonp/en.js'), import('intl/locale-data/jsonp/de.js')]),
//     ) // eslint-disable-line prettier/prettier
//     .then(() => render(translationMessages))
//     .catch((err) => {
//       throw err;
//     });
// } else {
render(translationMessages);
// }

export default render;

import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Text, Button } from '@ebay-certilogo/design-system-web';
import FormattedMessageMarkdown from 'components/FormattedMessageMarkdown';
import PoweredBy from 'components/PoweredBy';
import useEbayLink from 'components/hooks/useEbayLink';
import { makeAction } from 'components/cms/dataTypes/Button/actions';
import { setRedirect } from 'containers/Auth/actions';
import {
  Label,
  Value,
  Certificate,
  Row,
  ProductImage,
  Hologram,
  WavyLines,
  ContentWrapper,
  ProductImageWrapper,
} from './styles';
import messages from './messages';

const List = ({ items, colorType }) =>
  items.map(
    ({ label, value }) =>
      value && (
        <Row>
          <Label>
            <Text colorType={colorType} style={{ color: '#707070' }}>
              {label}
            </Text>
          </Label>
          <Value>
            <Text colorType={colorType}>{value}</Text>
          </Value>
        </Row>
      ),
  );

const logObject = ({ componentType }, name, link) => ({
  action: 'click',
  type: componentType,
  step: 'finish',
  start: 1,
  finish: 1,
  link,
  gtm: {
    tool: {
      name,
    },
  },
});

function PreAuthentication(props) {
  const { agTag = {}, colorType, propsBag: { logger } = {}, propsBag } = props;
  const dispatch = useDispatch();
  const { isLoading, isLinked, oAuthRedirect } = useEbayLink('ag');

  const handleOnClick = () => {
    if (logger) {
      logger(logObject(props, 'CTAbutton_agTag_showMeButton', 'start_authentication'));
    }
    if (!isLinked) {
      dispatch(setRedirect('/authentication', { sessionId: propsBag?.id }));
      oAuthRedirect();
    } else {
      makeAction('start_authentication', { propsBag });
    }
  };

  const list = [
    {
      label: <FormattedMessageMarkdown {...messages.productIdentifiers.brand} />,
      value: agTag.brand,
    },
    {
      label: <FormattedMessageMarkdown {...messages.productIdentifiers.model} />,
      value: agTag.model,
    },
    {
      label: <FormattedMessageMarkdown {...messages.productIdentifiers.category} />,
      value: agTag.itemListingCategory,
    },
  ];

  const productImage = useMemo(() => agTag?.itemImages && agTag?.itemImages[0], [agTag]);
  const is404 = useMemo(() => agTag?.error?.status === 404, [agTag]);
  const isError = useMemo(() => agTag?.error && agTag?.error?.status !== 404, [agTag]);

  if (isError) {
    return (
      <Text size="base" weight="extrabold" colorType={colorType} marginBottom="m">
        <FormattedMessageMarkdown {...messages.error} />
      </Text>
    );
  }

  return (
    <>
      <Text size="xl" weight="extrabold" colorType={colorType} marginBottom="m">
        <FormattedMessageMarkdown {...messages.title} />
      </Text>
      <Button
        onClick={handleOnClick}
        colorType={colorType}
        action="start_authentication"
        rounded
        arrow={false}
        size="m"
        width="auto"
        style={{ padding: '12px 24px', alignSelf: 'flex-start', backgroundColor: '#0968F6' }}
        marginBottom="m"
        loading={isLoading}
        uppercase={false}
      >
        <FormattedMessageMarkdown {...messages.button} />
      </Button>
      {!is404 && (
        <Certificate colorType={colorType} marginBottom="m">
          <Hologram>
            <WavyLines />
          </Hologram>
          <ContentWrapper>
            <ProductImageWrapper marginBottom="m">
              <ProductImage image={productImage} width="100px" />
            </ProductImageWrapper>
            <Text size="m" weight="extrabold" colorType={colorType} marginBottom="m">
              <FormattedMessageMarkdown {...messages.productIdentifiers.title} />
            </Text>
            <List items={list} colorType={colorType} />
          </ContentWrapper>
        </Certificate>
      )}
      <Text colorType={colorType} size="xs" textAlign="center" marginBottom="m">
        <FormattedMessageMarkdown {...messages.disclaimer} />
      </Text>
      <PoweredBy colorType={colorType} />
    </>
  );
}

PreAuthentication.propTypes = {
  colorType: PropTypes.string,
  logEventName: PropTypes.string,
  agTag: PropTypes.object,
  authentication: PropTypes.object,
  propsBag: PropTypes.object,
  componentType: PropTypes.string,
  gtm: PropTypes.object,
};

export default PreAuthentication;

import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Button, Modal } from '@ebay-certilogo/design-system-web';
import { FlowButton, ImagePreview, ImageWrapper, ModalBody } from '../styles';

const ImagePreviewModal = ({ messages, imagePreview, handleConfirm, handleRetake, ...rest }) => {
  const colorType = 'dark';
  return (
    <Modal colorType={colorType} {...rest}>
      <ModalBody>
        <ImageWrapper marginBottom="m">
          <ImagePreview src={imagePreview?.src} source={{ uri: imagePreview?.uri }} />
        </ImageWrapper>
        <FlowButton
          colorType={colorType}
          arrow={false}
          onClick={handleConfirm}
          noMargin
          style={{ width: '100%' }}
          disabled={!imagePreview}
          uppercase={false}
        >
          <FormattedMessage id={messages.confirm} defaultMessage={messages.confirm} />
        </FlowButton>
        <Button
          colorType={colorType}
          arrow={false}
          rounded
          size="s"
          style={{
            alignSelf: 'flex-start',
            border: '1px solid #0968F6',
            color: '#0968F6',
          }}
          fontSize="base"
          buttonType="transparent"
          onClick={handleRetake}
          uppercase={false}
        >
          <FormattedMessage id={messages.retake} defaultMessage={messages.retake} />
        </Button>
      </ModalBody>
    </Modal>
  );
};

ImagePreviewModal.propTypes = {
  messages: PropTypes.object,
  handleConfirm: PropTypes.func,
  handleRetake: PropTypes.func,
  imagePreview: PropTypes.object,
};

export default ImagePreviewModal;

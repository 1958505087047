import styled from 'styled-components';
import { Flex, ProgressBarTimer } from '@ebay-certilogo/design-system-web';
import { contentWrapper, margin } from '@ebay-certilogo/design-system-web/dist/shared/styles';

export const ModalBody = styled(Flex)`
  flex: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  ${contentWrapper}
`;

export const ProgressBar = styled(ProgressBarTimer).attrs({
  themeField: 'primaryAccentColor',
})`
  ${margin('l', 'top')}
  ${margin('s', 'bottom')}
`;

import { defineMessages } from 'react-intl';

const siAntiFraud = defineMessages({
  title: {
    id: 'entry_purpose_stone.title1',
    defaultMessage: 'How do you want to use Certilogo today?',
  },
});

const resellWithEbayPotentialBuyer = defineMessages({
  before: {
    id: 'resell_with_ebay.purpose.button1',
    defaultMessage: 'A listed product',
  },
  after: {
    id: 'resell_with_ebay.purpose.button2',
    defaultMessage: 'My own product',
  },
});

export default {
  'SI-AntiFraud': siAntiFraud,
  ResellWithEbayPotentialBuyer: resellWithEbayPotentialBuyer,
};

import { defineMessages } from 'react-intl';

const impDefault = defineMessages({
  title: {
    id: 'ebay_ag_tag.feature_widgets.post_authentication.imp.title',
    defaultMessage: "Looks like there's a problem.",
  },
  button1: {
    id: 'ebay_ag_tag.feature_widgets.post_authentication.imp.button1',
    defaultMessage: 'Contact support',
  },
  button2: {
    id: 'ebay_ag_tag.feature_widgets.post_authentication.imp.button2',
    defaultMessage: 'About Authenticity Guarantee',
  },
});

const impAgInvalidScreen = defineMessages({
  ...impDefault,
  text: {
    id: 'ebay_ag_tag.feature_widgets.post_authentication.imp_ag_invalid_screen.text',
    defaultMessage: "The uploaded photos aren't valid.",
  },
});

const impAgNoPictures = defineMessages({
  ...impDefault,
  text: {
    id: 'ebay_ag_tag.feature_widgets.post_authentication.imp_ag_no_pictures.subtitle',
    defaultMessage: 'You can either try again or get in touch for support.',
  },
  button1: {
    id: 'ebay_ag_tag.feature_widgets.post_authentication.imp_ag_no_pictures.button1',
    defaultMessage: 'Retry',
  },
  button2: {
    id: 'ebay_ag_tag.feature_widgets.post_authentication.imp_ag_no_pictures.button2',
    defaultMessage: 'Contact support',
  },
  button3: {
    id: 'ebay_ag_tag.feature_widgets.post_authentication.imp_ag_no_pictures.button3',
    defaultMessage: 'About Authenticity Guarantee',
  },
});

export default defineMessages({
  imp_ag_invalid_screen: impAgInvalidScreen,
  imp_ag_no_pictures: impAgNoPictures,
  imp_ag_not_active: impDefault,
  imp_ag_stolen: impDefault,
  imp_ag_blocked: impDefault,
  imp_ag_validate: impDefault,
  imp_ag_mint: impDefault,
});

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Div } from '@ebay-certilogo/design-system-web';
import styled from 'styled-components';
import '@ebay/skin/progress-bar-expressive';

const Wrapper = styled(Div)`
  width: 100%;
  --progress-bar-expressive-line-gap: 4px;
  --color-foreground-primary: #000;
  --font-size-large-1: 1.25rem;
  --font-weight-regular: 500;
  --spacing-50: 4px;
  --spacing-300: 24px;
  --spacing-200: 16px;
`;

const defaultMessages = [
  "We're finishing your order.",
  'Hang tight.',
  'Almost done...',
  'Your order is ready!',
];

const ProgressBarExpressive = ({ messages = defaultMessages, displayDuration = 3000, ...rest }) => {
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      setCurrentMessageIndex((prevIndex) => (prevIndex + 1) % messages.length);
    }, displayDuration);

    return () => clearTimeout(timer);
  }, [currentMessageIndex, displayDuration]);

  return (
    <Wrapper {...rest}>
      <div className="progress-bar-expressive">
        <div className="progress-bar-expressive__messages" style={{ color: '#000' }}>
          {messages.map((message, index) => (
            <div
              key={index}
              aria-hidden={index !== currentMessageIndex}
              className={`progress-bar-expressive__message ${
                index === currentMessageIndex
                  ? 'progress-bar-expressive__message--in'
                  : 'progress-bar-expressive__message--out'
              }`}
            >
              {message}
            </div>
          ))}
          <div
            role="status"
            id={`progress-bar-expressive-message-${currentMessageIndex}`}
            className="progress-bar-expressive__message"
          >
            {messages[currentMessageIndex]}
          </div>
        </div>
        <div
          role="progressbar"
          aria-label="Loading..."
          aria-describedby={`progress-bar-expressive-message-${currentMessageIndex}`}
          className="progress-bar-expressive__progress"
        >
          <div className="progress-bar-expressive__lines">
            <div className="progress-bar-expressive__line" />
            <div className="progress-bar-expressive__line" />
            <div className="progress-bar-expressive__line" />
            <div className="progress-bar-expressive__line" />
            <div className="progress-bar-expressive__line" />
            <div className="progress-bar-expressive__line" />
            <div className="progress-bar-expressive__line" />
            <div className="progress-bar-expressive__line" />
            <div className="progress-bar-expressive__line" />
            <div className="progress-bar-expressive__line" />
            <div className="progress-bar-expressive__line" />
            <div className="progress-bar-expressive__line" />
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

ProgressBarExpressive.propTypes = {
  messages: PropTypes.array,
  displayDuration: PropTypes.number,
};

export default ProgressBarExpressive;

import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import EbayButton from 'components/EbayButton';
import useEbayLink from 'components/hooks/useEbayLink';
import messages from '../messages';

function EbayLogin({ colorType, type = 'signUp', countryCode, onSubmit }) {
  const { isLoading, oAuthRedirect } = useEbayLink('default', countryCode);

  const handleOnClick = () => {
    oAuthRedirect();
    onSubmit();
  };

  return (
    <EbayButton isLoading={isLoading} colorType={colorType} onClick={handleOnClick} size="s">
      <FormattedMessage {...messages[type].ebay} />
    </EbayButton>
  );
}

EbayLogin.propTypes = {
  colorType: PropTypes.string,
  countryCode: PropTypes.string,
  type: PropTypes.string,
  setError: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default EbayLogin;

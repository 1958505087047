import React from 'react';
import PropTypes from 'prop-types';
import { Div } from '@ebay-certilogo/design-system-web';
import PostAuthentication from './PostAuthetication';
import PreAuthentication from './PreAuthetication';

function EbayAGTag({
  agTag = {},
  authentication = {},
  logEventName = 'ebay-ag-tag',
  style,
  propsBag,
  colorType,
  gtm,
}) {
  const childrenProps = { agTag, authentication, propsBag, logEventName, colorType, gtm };

  return (
    <Div {...style}>
      {authentication?.response ? (
        <PostAuthentication {...childrenProps} />
      ) : (
        <PreAuthentication {...childrenProps} />
      )}
    </Div>
  );
}

EbayAGTag.propTypes = {
  status: PropTypes.string,
  countryCode: PropTypes.string,
  componentType: PropTypes.string,
  style: PropTypes.object,
  button: PropTypes.object,
  colorType: PropTypes.string,
  logEventName: PropTypes.string,
  propsBag: PropTypes.object,
  gtm: PropTypes.object,
  agTag: PropTypes.object,
  authentication: PropTypes.object,
};

export default EbayAGTag;

import React from 'react';
import PropTypes from 'prop-types';
import { SendPhoto } from '@ebay-certilogo/design-system-web/dist/components/icons';
import { FormattedMessage } from 'react-intl';
import { Modal, Text } from '@ebay-certilogo/design-system-web';
import { ModalBody, ProgressBar } from './styles';

const UploadProgressModalCertilogo = ({ messages, ...rest }) => (
  <Modal {...rest} colorType="light">
    <ModalBody>
      <SendPhoto width={72} height={72} />
      <ProgressBar interval={3000} />
      <Text>
        <FormattedMessage id={messages.progress} defaultMessage={messages.progress} />
      </Text>
    </ModalBody>
  </Modal>
);

UploadProgressModalCertilogo.propTypes = {
  messages: PropTypes.object,
};

export default UploadProgressModalCertilogo;

import config from 'config';
import { fetchWrapper } from 'utils/Api/fetchWrapper';

const {
  oauth: { ebay },
} = config;

const oauthUrls = ebay?.urls || {};

const getOauthUrlByCountryCode = (countryCode) => oauthUrls[countryCode]?.url || oauthUrls.US.url;

const getFeatureScopes = async (feature) => {
  const res = await fetchWrapper({
    url: `ebay-account-linking/v2/feature/${feature}`,
    options: {
      method: 'GET',
    },
  });
  return res.scopes;
};

export default async (countryCode = 'US', feature = 'default') => {
  const scopes = await getFeatureScopes(feature);
  const scope = scopes.join(' ');
  const absoluteUrl = getOauthUrlByCountryCode(countryCode);
  const oaAuthUrl = `${absoluteUrl}/authorize?client_id=${ebay.clientId}&redirect_uri=${ebay.redirectUri}&response_type=code&scope=${scope}`;

  return oaAuthUrl;
};

import React from 'react';
import PropTypes from 'prop-types';
import { Div } from '@ebay-certilogo/design-system-web';
import PoweredBy from 'components/PoweredBy';
import FormattedMessageMarkdown from 'components/FormattedMessageMarkdown';
import ProgressBarExpressive from 'components/ebay/ProgressBarExpressive';
import { useTimeoutFn } from 'react-use';
import config from 'config';
import messages from './messages';
import { Wrapper } from './styles';

function Authentic({ colorType, agTag }) {
  useTimeoutFn(() => {
    window.location = `${config.web3AbsoluteUrl}/authenticity/${agTag?.agTagId}`;
  }, 3000);

  return (
    <Wrapper>
      <ProgressBarExpressive
        messages={[
          <FormattedMessageMarkdown {...messages.message1} />,
          <FormattedMessageMarkdown {...messages.message2} />,
        ]}
        style={{ marginTop: 'auto' }}
        displayDuration={1200}
      />
      <Div style={{ marginTop: 'auto' }}>
        <PoweredBy colorType={colorType} />
      </Div>
    </Wrapper>
  );
}

Authentic.propTypes = {
  colorType: PropTypes.string,
  logEventName: PropTypes.string,
  agTag: PropTypes.object,
  authentication: PropTypes.object,
  propsBag: PropTypes.object,
};

export default Authentic;

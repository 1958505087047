/* eslint-disable import/prefer-default-export */
import styled, { keyframes } from 'styled-components';
import Svg from 'react-inlinesvg';
import { Div, Flex } from '@ebay-certilogo/design-system-web';
import { padding } from '@ebay-certilogo/design-system-web/dist/shared/styles';
import Image from 'components/cms/dataTypes/Image';
import wavyLines from './wavy-lines.svg';

const BackgroundGradient = keyframes`
  0% {
    background-position: 0% 50%;
  }
  10% {
    background-position: 0% 50%;
  }
  45% {
    background-position: 100% 50%;
  }
  55% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`;

export const Certificate = styled(Div)`
  position: relative;
  overflow: hidden;
  border-radius: 24px;
  background: #f3f5f9;
  ${padding('m', 'horizontal')};
  ${padding('m', 'vertical')};
`;

export const Row = styled(Flex)`
  flex: 1;
  margin-bottom: 15px;
`;

export const Label = styled(Div)`
  width: 100%;
  max-width: 120px;
`;

export const Value = styled(Div)`
  width: 100%;
`;

export const ProductImageWrapper = styled(Div)`
  width: 180px;
  height: 180px;
  border-radius: 24px;
  background: #fff;
  overflow: hidden;
`;

export const ProductImage = styled(Image)`
  width: 100%;
  height: 100%;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
`;

export const ContentWrapper = styled(Div)`
  position: relative;
  z-index: 1;
`;

export const WavyLines = styled(Svg).attrs({
  src: wavyLines,
})`
  position: absolute;
  top: 0;
  left: -10px;
`;

export const Hologram = styled(Div)`
  --min-certificate-height: 480px;

  --light-purple: rgba(210, 191, 246, 1);
  --gradient-light-purple: radial-gradient(ellipse at 0%, var(--light-purple) 10%, transparent 25%);

  --light-blue: rgba(160, 219, 249, 1);
  --gradient-light-blue: radial-gradient(ellipse at 0%, var(--light-blue) 20%, transparent 35%);

  --light-green: rgba(207, 247, 234, 1);
  --gradient-light-green: radial-gradient(ellipse at 0%, var(--light-green) 30%, transparent 45%);

  --light-yellow: rgba(250, 250, 219, 1);
  --gradient-light-yellow: radial-gradient(ellipse at 0%, var(--light-yellow) 40%, transparent 55%);

  --light-orange: rgba(249, 224, 209, 1);
  --gradient-light-orange: radial-gradient(ellipse at 0%, var(--light-orange) 50%, transparent 65%);

  --light-red: rgba(241, 199, 223, 1);
  --gradient-light-red: radial-gradient(ellipse at 0%, var(--light-red) 60%, transparent 75%);

  --dark-blue: rgba(160, 219, 249, 1);
  --gradient-dark-blue: radial-gradient(ellipse at 100%, var(--dark-blue) 50%, transparent 100%);

  --gradient: var(--gradient-light-purple), var(--gradient-light-blue), var(--gradient-light-green),
    var(--gradient-light-yellow), var(--gradient-light-orange), var(--gradient-light-red),
    var(--gradient-dark-blue);

  width: 100%;
  height: 200px;
  top: -50px;
  left: 0;
  z-index: 0;
  position: absolute;
  background: var(--gradient);
  background-size: 400% 400%;
  background-position: 0% 50%;
  animation: ${BackgroundGradient} 30s ease-in-out infinite;

  &::after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 0;
    right: 0;
    height: 75%;
    z-index: 0;
    background: linear-gradient(0deg, #f3f5f9 25%, rgba(247, 247, 247, 0) 100%);
  }
`;

import React from 'react';
import PropTypes from 'prop-types';
import PoweredBy from 'components/PoweredBy';
import reasons from './reasons';

function Impossible(props) {
  const { authentication, colorType } = props;
  const reason = authentication?.reason;
  const childrenProps = { ...props, reason };

  return (
    <>
      {reasons[reason]?.title && reasons[reason].title(childrenProps)}
      {reasons[reason]?.subtitle && reasons[reason].subtitle(childrenProps)}
      {reasons[reason]?.text && reasons[reason].text(childrenProps)}
      {reasons[reason]?.button1 && reasons[reason].button1(childrenProps)}
      {reasons[reason]?.button2 && reasons[reason].button2(childrenProps)}
      {reasons[reason]?.button3 && reasons[reason].button3(childrenProps)}
      <PoweredBy colorType={colorType} />
    </>
  );
}

Impossible.propTypes = {
  colorType: PropTypes.string,
  logEventName: PropTypes.string,
  agTag: PropTypes.object,
  authentication: PropTypes.object,
  propsBag: PropTypes.object,
};

export default Impossible;

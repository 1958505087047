import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Button, Modal } from '@ebay-certilogo/design-system-web';
import { ImagePreview, ImageWrapper, ModalBody, FlowButton } from '../styles';

const ImagePreviewModal = ({ messages, imagePreview, handleConfirm, handleRetake, ...rest }) => (
  <Modal {...rest}>
    <ModalBody>
      <ImageWrapper marginBottom="m">
        <ImagePreview src={imagePreview?.src} source={{ uri: imagePreview?.uri }} />
      </ImageWrapper>
      <FlowButton
        onClick={handleConfirm}
        noMargin
        style={{ width: '100%' }}
        disabled={!imagePreview}
      >
        <FormattedMessage id={messages.confirm} defaultMessage={messages.confirm} />
      </FlowButton>
      <Button buttonType="link" arrow={false} onClick={handleRetake} noMargin>
        <FormattedMessage id={messages.retake} defaultMessage={messages.retake} />
      </Button>
    </ModalBody>
  </Modal>
);

ImagePreviewModal.propTypes = {
  messages: PropTypes.object,
  handleConfirm: PropTypes.func,
  handleRetake: PropTypes.func,
  imagePreview: PropTypes.object,
};

export default ImagePreviewModal;

import styled from 'styled-components';
import { Div, Flex, Image } from '@ebay-certilogo/design-system-web';
import { contentWrapper } from '@ebay-certilogo/design-system-web/dist/shared/styles';
import _FlowButton from 'containers/Authentication/components/FlowButton';

export const ImageWrapper = styled(Div)`
  display: flex;
  justify-content: center;
  height: 280px;
  width: 280px;
  overflow: hidden;
`;

export const ImagePreview = styled(Image)`
  height: fit-content;
  width: auto;
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
`;

export const FlowButton = styled(_FlowButton)`
  width: 100%;
`;

export const ModalBody = styled(Flex)`
  flex: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  ${contentWrapper}
`;

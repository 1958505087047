import { defineMessages } from 'react-intl';

const agValidation = defineMessages({
  title: {
    id: 'ag-validation.where.title1',
    defaultMessage: 'How did you get this product?',
  },
  gift: {
    id: 'ag-validation.where.gift',
    defaultMessage: 'As a gift',
  },
});

export default {
  'ag-validation': agValidation,
};

import React from 'react';
import { FormattedMessage } from 'react-intl';
import FormattedMessageMarkdown from 'components/FormattedMessageMarkdown';
import PropTypes from 'prop-types';
import { Button, Text, ModalAssistant } from '@ebay-certilogo/design-system-web';

function PreprocessingTimeoutModal({ isOpen, messages, onSkip, onRetry, onClose }) {
  return (
    <ModalAssistant
      colorType="light"
      isOpen={isOpen}
      onClose={onClose}
      text={
        <Text colorType="light" marginBottom="s">
          <FormattedMessageMarkdown
            id={messages.preprocessingTimeoutModal.text}
            defaultMessage={messages.preprocessingTimeoutModal.text}
          />
        </Text>
      }
      footer={
        <>
          <Button colorType="light" noMargin onClick={onRetry}>
            <FormattedMessage
              id={messages.preprocessingTimeoutModal.retry}
              defaultMessage={messages.preprocessingTimeoutModal.retry}
            />
          </Button>
          <Button colorType="light" buttonType="link" arrow={false} onClick={onSkip}>
            <FormattedMessage
              id={messages.preprocessingTimeoutModal.skip}
              defaultMessage={messages.preprocessingTimeoutModal.skip}
            />
          </Button>
        </>
      }
    />
  );
}

PreprocessingTimeoutModal.propTypes = {
  colorType: PropTypes.string,
  onSkip: PropTypes.func,
  onClose: PropTypes.func,
  onRetry: PropTypes.func,
  isOpen: PropTypes.bool,
  messages: PropTypes.object,
};

export default PreprocessingTimeoutModal;

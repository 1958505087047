import React from 'react';
import PropTypes from 'prop-types';
import Authentic from './Authentic';
import Impossible from './Impossible';

function PostAuthentication(props) {
  const { authentication } = props;
  return authentication?.response === 'authentic' ? (
    <Authentic {...props} />
  ) : (
    <Impossible {...props} />
  );
}

PostAuthentication.propTypes = {
  colorType: PropTypes.string,
  authentication: PropTypes.object,
};

export default PostAuthentication;

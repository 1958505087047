import { defineMessages } from 'react-intl';

const productIdentifiers = defineMessages({
  title: {
    id: 'ebay_ag_tag.feature_widgets.product_identifiers.title',
    defaultMessage: 'Product identifiers',
  },
  brand: {
    id: 'ebay_ag_tag.feature_widgets.product_identifiers.brand',
    defaultMessage: 'Brand',
  },
  model: {
    id: 'ebay_ag_tag.feature_widgets.product_identifiers.model',
    defaultMessage: 'Model',
  },
  type: {
    id: 'ebay_ag_tag.feature_widgets.product_identifiers.type',
    defaultMessage: 'Type',
  },
  department: {
    id: 'ebay_ag_tag.feature_widgets.product_identifiers.department',
    defaultMessage: 'Department',
  },
  category: {
    id: 'ebay_ag_tag.feature_widgets.product_identifiers.category',
    defaultMessage: 'Category',
  },
  brandMaterial: {
    id: 'ebay_ag_tag.feature_widgets.product_identifiers.brandMaterial',
    defaultMessage: 'Brand Material',
  },
});

export default defineMessages({
  error: {
    id: 'ebay_ag_tag.feature_widgets.error',
    defaultMessage: 'An error has occurred.',
  },
  title: {
    id: 'ebay_ag_tag.feature_widgets.title',
    defaultMessage: 'Get your authenticity certificate. From eBay with love',
  },
  button: {
    id: 'ebay_ag_tag.feature_widgets.button',
    defaultMessage: 'Show me',
  },
  productIdentifiers,
  disclaimer: {
    id: 'ebay_ag_tag.feature_widgets.disclaimer',
    defaultMessage:
      'By continuing, you agree to the [eBay Watches T&Cs](https://pages.ebay.co.uk/authenticity-guarantee-watches/terms) and understand and acknowledge that an immutable Token for the [eBay Authenticity Certificate](https://pages.ebay.co.uk/authenticity-guarantee-watches/faqs) will be created.',
  },
});

import { call, put, select } from 'redux-saga/effects';
import fetchWrapper from 'utils/Api/fetchWrapper';
import { isBrandedDomain } from 'utils/utils';
import { setConf, setConfBrandedDomain } from 'containers/App/actions';
import { push } from 'connected-react-router';
import { makeSelectLocale } from 'containers/LanguageProvider/selectors';
import { getConnectResponse } from '../actions';

export default function* getAuthenticationResponseConf({ payload: { sessionId } }) {
  const url = 'connect/config';
  // const url = 'http://localhost:3103/connect/config';

  try {
    const lang = yield select(makeSelectLocale);
    const response = yield call(fetchWrapper, {
      url,
      withQuery: true,
      params: {
        sessionId,
        lang,
      },
    });

    if (response.conf) {
      if (isBrandedDomain()) {
        yield put(setConfBrandedDomain(response.conf));
      } else {
        yield put(setConf(response.conf));
      }
    }

    yield put(getConnectResponse(response));
  } catch ({ response }) {
    yield put(getConnectResponse());
    if (response?.status === 404) {
      yield put(push(`/code`));
    }
  }
}

import history from 'utils/history';

const actions = {
  connect: ({ authentication }) => {
    history.push(`/connect/${authentication?.sessionId}`);
  },
  'resell-with-ebay': ({ authentication }) => {
    history.push(`/resell-with-ebay`, { sessionId: authentication?.sessionId });
  },
};

export const completeActionFn = (action, props) => {
  const actionFn = actions[action];
  if (actionFn) {
    actionFn(props);
  }
};

export default completeActionFn;

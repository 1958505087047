import React from 'react';
import useUiDesginSystemComponentSwitcher from 'components/hooks/useUiDesginSystemComponentSwitcher';
import ImagePreviewModalCertilogo from './certilogo';
import ImagePreviewModalEbay from './ebay';

const componentsMap = {
  certilogo: ImagePreviewModalCertilogo,
  ebay: ImagePreviewModalEbay,
};

const ImagePreviewModal = (props) => {
  const Component = useUiDesginSystemComponentSwitcher(componentsMap);
  return <Component {...props} />;
};

export default ImagePreviewModal;

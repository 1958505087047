import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Button, Div, Modal, Text } from '@ebay-certilogo/design-system-web';
import { setRedirect, setRedirectError } from 'containers/Auth/actions';
import EbayButton from 'components/EbayButton';
import history from 'utils/history';
import FormattedMessageMarkdown from 'components/FormattedMessageMarkdown';
import TermsAndConditions from 'components/TermsAndConditions';
import PoweredBy from 'components/PoweredBy';
import useEbayLink from 'components/hooks/useEbayLink';
import messages, { statusesMessages } from './messages';

const AUTHENTICATION_FLOW_NAME = 'Auth-ResellWithEbay';

function ResellWithEbay({
  available,
  countryCode,
  style,
  logEventName = 'resell-with-ebay',
  propsBag: { logger } = {},
  propsBag,
  componentType,
  colorType,
  gtm,
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dispatch = useDispatch();
  const { isLoading, isLinked, oAuthRedirect } = useEbayLink('rwe', countryCode);

  const handleOnClick = () => {
    if (available?.status === 'beforeAuthentication' && !isModalOpen) {
      setIsModalOpen(true);
    } else if (!isLinked) {
      dispatch(setRedirectError(`/connect/${propsBag?.id}`));
      if (available?.status === 'beforeAuthentication') {
        dispatch(
          setRedirect('/authentication', {
            sessionId: propsBag?.id,
            flow: AUTHENTICATION_FLOW_NAME,
          }),
        );
      } else {
        dispatch(setRedirect('/resell-with-ebay', { sessionId: propsBag?.id }));
      }

      oAuthRedirect();
    } else if (available?.status === 'beforeAuthentication') {
      history.push(`/authentication`, { sessionId: propsBag?.id, flow: AUTHENTICATION_FLOW_NAME });
    } else if (available?.status === 'complete' && available?.draftListUrl) {
      window.open(available?.draftListUrl);
    } else {
      history.push(`/resell-with-ebay`, { sessionId: propsBag?.id });
    }

    if (logger) {
      logger({
        action: 'click',
        type: componentType,
        step: 'finish',
        start: 1,
        finish: 1,
        gtm,
        event: { type: 'service-clg', name: logEventName },
      });
    }
  };

  const statusMessages = useMemo(
    () => ({
      ...messages,
      ...statusesMessages[available?.status],
    }),
    [available?.status],
  );

  return (
    <Div {...style}>
      <EbayButton
        fontSize="base"
        fontWeight="400"
        loading={isLoading}
        uppercase={false}
        onClick={handleOnClick}
        arrow={false}
      >
        <FormattedMessageMarkdown {...statusMessages.button} />
      </EbayButton>
      <Modal colorType={colorType} isOpen={isModalOpen} close onClose={() => setIsModalOpen(false)}>
        <Div textAlign="center">
          <Text colorType={colorType} weight="extrabold" size="m" marginBottom="s">
            <FormattedMessageMarkdown {...statusMessages.modalBeforeAuthentication.title} />
          </Text>
          <Text colorType={colorType} size="m" marginBottom="s">
            <FormattedMessageMarkdown {...statusMessages.modalBeforeAuthentication.text1} />
          </Text>
          <Text colorType={colorType} size="m" marginBottom="s">
            <FormattedMessageMarkdown {...statusMessages.modalBeforeAuthentication.text2} />
          </Text>
          <Button colorType={colorType} marginBottom="m" onClick={handleOnClick}>
            <FormattedMessageMarkdown {...statusMessages.modalBeforeAuthentication.button} />
          </Button>
          <TermsAndConditions colorType={colorType} type="signup" size="xxs" marginBottom="m" />
          <PoweredBy colorType={colorType} />
        </Div>
      </Modal>
    </Div>
  );
}

ResellWithEbay.propTypes = {
  available: PropTypes.object,
  countryCode: PropTypes.string,
  componentType: PropTypes.string,
  style: PropTypes.object,
  colorType: PropTypes.string,
  logEventName: PropTypes.string,
  propsBag: PropTypes.object,
  gtm: PropTypes.object,
};

export default ResellWithEbay;

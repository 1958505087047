import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Modal } from '@ebay-certilogo/design-system-web';
import ProgressBarExpressive from 'components/ebay/ProgressBarExpressive';
import { ModalBody } from './styles';

const UploadProgressModalEbay = ({ messages, ...rest }) => (
  <Modal {...rest} colorType="dark">
    <ModalBody>
      <ProgressBarExpressive
        messages={[<FormattedMessage id={messages.progress} defaultMessage={messages.progress} />]}
      />
    </ModalBody>
  </Modal>
);

UploadProgressModalEbay.propTypes = {
  messages: PropTypes.object,
};

export default UploadProgressModalEbay;

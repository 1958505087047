import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { flowRequest } from 'containers/Authentication/actions';
import { setBack } from 'containers/App/actions';
import get from 'lodash/get';
import withColorType from 'components/HOC/withColorType';
import HeaderBox from 'containers/Authentication/components/HeaderBox';
import FlowButton from 'containers/Authentication/components/FlowButton';
import getFlowMessages from './messages';

function Where({ colorType, data, authenticationId }) {
  const dispatch = useDispatch();
  const { status, props } = data;
  const giftVisible = get(props, 'gift.visible');
  const onlineVisible = get(props, 'online.visible');
  const storeVisible = get(props, 'store.visible');

  const messages = useMemo(() => getFlowMessages(data?.flow), [data?.flow]);

  useEffect(() => {
    if (data) {
      dispatch(
        setBack({
          back: flowRequest({
            action: 'prev',
            status,
            authenticationId,
          }),
        }),
      );
    }
  }, [data]);

  const handleSubmit = (choise) => {
    dispatch(
      flowRequest({
        action: 'next',
        status,
        authenticationId,
        data: { bought: choise },
      }),
    );
  };

  return (
    <>
      <HeaderBox monochrome colorType={colorType} text={<FormattedMessage {...messages.title} />} />
      {storeVisible && (
        <FlowButton onClick={() => handleSubmit('store')} colorType={colorType} noMargin>
          <FormattedMessage {...messages.store} />
        </FlowButton>
      )}
      {onlineVisible && (
        <FlowButton onClick={() => handleSubmit('online')} colorType={colorType} noMargin>
          <FormattedMessage {...messages.online} />
        </FlowButton>
      )}
      {giftVisible && (
        <FlowButton onClick={() => handleSubmit('gift')} colorType={colorType}>
          <FormattedMessage {...messages.gift} />
        </FlowButton>
      )}
    </>
  );
}

Where.propTypes = {
  colorType: PropTypes.string,
  authenticationId: PropTypes.string,
  data: PropTypes.shape({
    status: PropTypes.string,
    certilogoCode: PropTypes.string,
    flow: PropTypes.string,
    props: PropTypes.object,
  }),
};

export default withColorType(Where);

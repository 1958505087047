import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Div } from '@ebay-certilogo/design-system-web';
import {
  PoweredBy as PoweredByClg,
  PoweredByClgEbay,
} from '@ebay-certilogo/design-system-web/dist/components/icons';
import { selectGlobalColorType } from 'containers/App/selectors';
import { color } from '@ebay-certilogo/design-system-web/dist/shared/styles/themes/selectors';
import useUiDesginSystemComponentSwitcher from 'components/hooks/useUiDesginSystemComponentSwitcher';

const Wrapper = styled(Div)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 0;
  color: ${color};
`;

const Certilogo = styled(PoweredByClg).attrs({ height: 20 })`
  height: 20px;
`;

const Ebay = styled(PoweredByClgEbay).attrs({ height: 57 })`
  width: 100%;
  height: 57px;
`;

const componentsMap = {
  certilogo: Certilogo,
  ebay: Ebay,
};

function PoweredBy(props) {
  const colorType = useSelector(selectGlobalColorType);
  const Component = useUiDesginSystemComponentSwitcher(componentsMap);
  return (
    <Wrapper colorType={colorType}>
      <Component colorType={colorType} {...props} />
    </Wrapper>
  );
}

export default PoweredBy;

/* eslint-disable react/no-unstable-nested-components */
/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switch } from 'react-router-dom';
import { GlobalStyle } from '@ebay-certilogo/design-system-web';
import * as Sentry from '@sentry/react';
import { EbaySvg } from '@ebay/ui-core-react/ebay-svg';

import Route from 'components/Route';
import ErrorComponent from 'components/ErrorComponent';
import Home from 'containers/Home';
import authSaga from 'containers/Auth/saga';
import { useInjectSaga } from 'utils/injectSaga';
import AppLoader from 'components/AppLoader';
import saga from './saga';
import Layout from './Layout';
import { initApp } from './actions';
import { selectAppLoading } from './selectors';
import Routing from './Routing';
import MetaHeader from './MetaHeader';

export default function App() {
  useInjectSaga({ key: 'app', saga });
  useInjectSaga({ key: 'auth', saga: authSaga });
  const dispatch = useDispatch();
  const isAppLoading = useSelector(selectAppLoading);

  useEffect(() => {
    dispatch(initApp());
  }, [initApp]);

  if (isAppLoading) return <AppLoader />;

  return (
    <>
      <Sentry.ErrorBoundary fallback={ErrorComponent}>
        <MetaHeader />
        <EbaySvg />
        <Switch>
          <Route exact path="/" component={Home} colorType="light" />
          <Route
            component={() => (
              <Layout>
                <Sentry.ErrorBoundary fallback={ErrorComponent}>
                  <Routing />
                </Sentry.ErrorBoundary>
              </Layout>
            )}
          />
        </Switch>
      </Sentry.ErrorBoundary>

      <GlobalStyle />
    </>
  );
}

import { defineMessages } from 'react-intl';

export default defineMessages({
  message1: {
    id: 'ebay_ag_tag.feature_widgets.post_authentication.authentic.message1',
    defaultMessage: 'Taking you to your...',
  },
  message2: {
    id: 'ebay_ag_tag.feature_widgets.post_authentication.authentic.message2',
    defaultMessage: '...authentic luxury experience',
  },
});

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectIsAuthenticated,
  selectProfile,
  selectProfileLoading,
} from 'containers/Auth/selectors';
import { getProfileRequest, setEbayOauthFeature } from 'containers/Auth/actions';
import ebayOauth from 'containers/Auth/Login/EbayLogin/ebayOauth';

function useEbayLink(feature = 'default', countryCode) {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const isProfileLoading = useSelector(selectProfileLoading);
  const profileData = useSelector(selectProfile);
  const [ebayOauthUrl, setEbayOauthUrl] = useState();
  const isLoading = isProfileLoading;

  useEffect(() => {
    async function asyncData() {
      const eBayOauthUrl = await ebayOauth(countryCode, feature);
      dispatch(setEbayOauthFeature(feature));
      setEbayOauthUrl(eBayOauthUrl);
    }
    asyncData();
  }, [isAuthenticated, feature]);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(getProfileRequest());
    }
  }, [isAuthenticated]);

  const ebayFeatures = profileData?.ebayFeatures || [];
  const isFeatureListed = ebayFeatures.includes(feature);
  const isLinked = isAuthenticated && isFeatureListed;

  const oAuthRedirect = () => {
    window.location = ebayOauthUrl;
    return true;
  };

  return { isLoading, isLinked, oAuthRedirect };
}

export default useEbayLink;

import { defineMessages } from 'react-intl';
import flowMessages from './flowMessages';

const defaultMessages = defineMessages({
  title: {
    id: 'entry_purpose.title1',
    defaultMessage: 'How do you want to use Certilogo today?',
  },
  before: {
    id: 'entry_purpose.button1',
    defaultMessage: 'Before purchase',
  },
  after: {
    id: 'entry_purpose.button2',
    defaultMessage: 'After purchase',
  },
  sell: {
    id: 'entry_purpose.button3',
    defaultMessage: 'To sell',
  },
});

export default (flow) => {
  const overrideMessages = flowMessages[flow] || {};
  return {
    ...defaultMessages,
    ...overrideMessages,
  };
};

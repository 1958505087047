/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import { Flex } from '@ebay-certilogo/design-system-web';
import { media } from '@ebay-certilogo/design-system-web/dist/shared/styles';

export const Wrapper = styled(Flex)`
  height: calc(100vh - 80px);
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${media.tablet`
    height: calc(100vh - 110px);
  `};
`;
